import Plyr from 'plyr';

document.addEventListener('DOMContentLoaded', () => {

  const players = document.getElementsByClassName('plyr-player');

  for (const playerElement of players) {

    const play = playerElement.parentElement.getElementsByClassName('plyr-player-play')[0];

    const player = new Plyr(playerElement, {
      title: 'Embedded video',
      controls: ['play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'pip', 'airplay', 'fullscreen'],
      muted: true,
      autoplay: false,
    });

    play.addEventListener('click', () => {
      play.style.visibility = 'hidden';

      player.play();
    })
  }

});